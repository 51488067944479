.profilePice {
  border-radius: 50%;
  margin: 15% 45%;
  border-color: blue;
  border-radius: 5%;
}

.profilePic {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 9999px;
}

.photoOld {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.photo {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.conicGradient:after {
  display: block;
  border-radius: 9999px;
  content: '';
  width: 108%;
  height: 108%;
  position: absolute;
  z-index: -1;
  left: -4%;
  top: -4%;
  background-image: conic-gradient(#00aeef, #fff, #00aeef, #fff, #00aeef);
  animation: spin 5s linear infinite;
}

.fadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
